.pleine-page {
    background: #f4f4f4;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.loading {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: white;
    width: 268px;
    height: 140px;
    border-radius: 16px;
    box-shadow: 0 1px 4px 0 #00000080;
    padding: 24px;

    .content {
        position: absolute;
        top: 0;

        .lock {
            position: relative;
            width: 76px;
            height: 65px;
            background-color: white;
            border: 8px solid #6c6c6c;
            border-radius: 18px;
            margin-bottom: 16px;
            transform-origin: 0 100%;
            animation: inclination 500ms cubic-bezier(0, 0.63, 1, 0.39);

            &:before {
                content: "";
                width: 8px;
                height: 16px;
                border-radius: 8px;
                background: #616161;
                position: absolute;
                display: block;
                left: 50%;
                top: 50%;
                margin: -9px 0 0 -3px;
            }

            &:after {
                content: "";
                display: block;
                border-top-left-radius: 30px;
                border-top-right-radius: 30px;
                border: 8px solid #6c6c6c;
                border-bottom: 0;
                width: 46px;
                height: 36px;
                left: 7px;
                bottom: 53px;
                position: absolute;
                z-index: 1;
                transform-origin: 0 100%;
                transform: rotate(-35deg) translate(0, 1px);
                animation: lock 500ms ease 200ms forwards;
            }

            .line {
                content: "";
                opacity: 0;
                background-color: #00874e;
                height: 2px;
                width: 12px;
                position: absolute;
                right: -12px;
                transform: rotate(-49deg);
                bottom: 65px;
                border-radius: 2px;
                animation: lines 500ms linear 400ms forwards reverse;

                &:nth-child(2) {
                    transform: rotate(-80deg);
                    width: 7px;
                    right: -3px;
                    bottom: 66px;
                }

                &:nth-child(3) {
                    width: 11px;
                    right: -14px;
                    transform: rotate(-22deg);
                    bottom: 59px;
                }
            }
        }
        .spinner-content {
            position: absolute;
            bottom: 8px;
            right: -12px;
            background-color: white;
            border-radius: 50%;
            width: 32px;
            height: 32px;
        }
        .dsd-loading-host {
            position: absolute;
            top: -19px;
            left: -18px;
            margin-top: 0;
            margin-left: 0;
            display: block;
            transform: scale(0.50);
        }
    }
    .text-loading {
        padding: 55px 0 0 0;

        p {
            margin: 0;
            font-size: 16px;
            text-align: center;
            color: #2f2f2f;
        }
    }
}
@keyframes inclination {
    0% {
        transform: rotate(-8deg);
    }
    20% {
        transform: rotate(-5deg);
    }
    100% {
        transform: rotate(0deg);
    }
}
@keyframes lock {
    0% {
        transform: rotate(-35deg) translate(0, 1px);
    }
    100% {
        transform: rotate(0deg) translate(0, 0);
    }
}
@keyframes lines {
    0% {
        display: none;
        opacity: 0;
        width: 0;
    }
    70% {
        display: block;
        opacity: 1;
    }
    90% {
        display: none;
        opacity: 0;
    }
}
