.App {
    background-color: #f4f4f4!important;
}

.zone {
    align-items: flex-start;
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    position: absolute;
}

.backdrop {
    background: #00000099;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    position: absolute;
    z-index: 1;
}

.content-inner {
    margin: auto 0;
    position: relative;
    z-index: 2;
}

.spinner {
    background-color: #f4f4f4;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    padding: 24px;
}

.conseil-perso dsd-container {
    margin-bottom: 24px;
}

dsd-banner, .dsd-banner-host, .dsd-accordion-host div.dsd-accordion, dsd-alert {
    max-width: 100%;
}

/* DEBUT - CORRECTION POUR LA BANNIERE TNR À CAUSE DES CSP */
.QSI_FR_1 {
    width: 100%;
    height: 85px;
    overflow: hidden;
    position: relative;
}

.QSIInfoBar .QSI_FR_2 {
    position: absolute;
    z-index: 2000000000;
    width: 100%;
    height: 85px;
    background-color: rgb(218, 229, 226);
    border-width: 0px;
    border-color: rgb(203, 204, 204);
    border-style: solid;
    border-radius: 0px;
    display: block;
    left: 0px;
    top: 0px;
}

.QSIInfoBar .QSI_FR_3 {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 1189.99px;
    height: 85px;
    overflow: hidden;
    display: block;
}

.QSIInfoBar .QSI_FR_4 {
    position: absolute;
    z-index: 2000000005;
    width: 357px;
    height: 61px;
    background-color: transparent;
    border-width: 0px;
    border-color: transparent;
    border-style: solid;
    border-radius: 0px;
    display: block;
    left: 100px;
    top: 7px;
}

.QSIInfoBar .QSI_FR_5 {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 357px;
    height: 61px;
    overflow: hidden;
    display: block;
}

.QSIInfoBar .QSI_FR_6 {
    font-size: 22px;
    font-weight: 700;
    padding: 10px 0;
    font-family: roboto,arial,helvetica,sans-serif;
    color: #383838;
}

.QSIInfoBar .QSI_FR_7 {
    position: absolute;
    z-index: 2000000002;
    width: 17px;
    height: 17px;
    background-color: transparent;
    border-width: 0px;
    border-color: rgb(51, 51, 51);
    border-style: solid;
    border-radius: 0px;
    display: block;
    right: 17px;
    top: 12px;
    cursor: pointer;
}

.QSIInfoBar .QSI_FR_8 {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 17px;
    height: 17px;
    overflow: hidden;
    display: block;
}

.QSIInfoBar .QSI_FR_11 {
    position: absolute;
    z-index: 2000000004;
    width: 40px;
    height: 40px;
    background-color: transparent;
    border-width: 1px;
    border-color: transparent;
    border-style: solid;
    border-radius: 0px;
    display: block;
    left: 45px;
    top: 25px;
}

.QSIInfoBar .QSI_FR_12 {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 40px;
    height: 40px;
    overflow: hidden;
    display: block;
}

.QSIInfoBar .QSI_FR_16 {
    position: absolute;
    z-index: 2000000001;
    width: 132px;
    height: 23px;
    background-color: transparent;
    border-width: 0px;
    border-color: transparent;
    border-style: solid;
    border-radius: 0px;
    display: block;
    left: 463px;
    top: 25px;
}

.QSIInfoBar .QSI_FR_17 {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 132px;
    height: 23px;
    overflow: hidden;
    display: block;
}

.QSIInfoBar .QSI_FR_18 {
    margin: 0px 0px 2.1px;
    font-variant-numeric: normal;
    font-variant-east-asian: normal;
    font-stretch: normal;
    font-size: 14px;
    line-height: normal;
    font-family: Arial;
    color: #000;
    font-weight: 400;
}

.QSIInfoBar .QSI_FR_19 {
    font-size: 16px;
}

.QSIInfoBar .QSI_FR_20 {
    font-family: arial,helvetica,sans-serif;
}

.QSIInfoBar .QSI_FR_21 {
    position: absolute;
    z-index: 2000000003;
    width: 232px;
    height: 38px;
    background-color: transparent;
    border-width: 0px;
    border-color: rgb(0, 0, 0);
    border-style: solid;
    border-radius: 0px;
    display: block;
    left: 430px;
    top: 40px;
    cursor: pointer;
}

.QSIInfoBar .QSI_FR_22 {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 232px;
    height: 38px;
    overflow: hidden;
    display: block;
}

.QSIInfoBar .QSI_FR_23 {
    color: #007944;
    font-size: 16px;
    padding: 9px 16px;
    font-weight: 700;
    text-align: center;
}

.QSIInfoBar .QSI_FR_24 {
    vertical-align: middle;
    margin-right: 4px;
}

.QSIInfoBar .QSI_FR_27 {
    vertical-align: middle;
}

.QSIInfoBar .QSI_FR_28 {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    white-space: nowrap;
    border: 0;
}

.QSI_EN_1 {
    width: 100%;
    overflow: hidden;
    position: relative;
}

.QSIInfoBar .QSI_EN_2 {
    position: absolute;
    z-index: 2000000000;
    height: 85px;
    background-color: rgb(218, 229, 226);
    border-width: 0px;
    border-color: rgb(203, 204, 204);
    border-style: solid;
    left: 0px;
    top: 0px;
    width: 100%;
}

.QSIInfoBar .QSI_EN_3 {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 85px;
    overflow: hidden;
}

.QSIInfoBar .QSI_EN_4 {
    position: absolute;
    z-index: 2000000005;
    width: 357px;
    height: 61px;
    background-color: transparent;
    border-width: 0px;
    border-color: transparent;
    border-style: solid;
    border-radius: 0px;
    display: block;
    left: 100px;
    top: 7px;
}

.QSIInfoBar .QSI_EN_5 {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 357px;
    height: 61px;
    overflow: hidden;
    display: block;
}

.QSIInfoBar .QSI_EN_6 {
    font-size: 22px;
    font-weight: 700;
    padding: 10px 0;
    font-family: roboto,arial,helvetica,sans-serif;
    color: #383838;
}

.QSIInfoBar .QSI_EN_8 {
    position: absolute;
    z-index: 2000000002;
    width: 17px;
    height: 17px;
    background-color: transparent;
    border-width: 0px;
    border-color: rgb(51, 51, 51);
    border-style: solid;
    border-radius: 0px;
    display: block;
    right: 17px;
    top: 12px;
    cursor: pointer;
}

.QSIInfoBar .QSI_EN_9 {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 17px;
    height: 17px;
    overflow: hidden;
    display: block;
}

.QSIInfoBar .QSI_EN_12 {
    position: absolute;
    z-index: 2000000004;
    width: 40px;
    height: 40px;
    background-color: transparent;
    border-width: 1px;
    border-color: transparent;
    border-style: solid;
    border-radius: 0px;
    display: block;
    left: 45px;
    top: 25px;
}

.QSIInfoBar .QSI_EN_13 {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 40px;
    height: 40px;
    overflow: hidden;
    display: block;
}

.QSIInfoBar .QSI_EN_17 {
    position: absolute;
    z-index: 2000000001;
    width: 180px;
    height: 21px;
    background-color: transparent;
    border-width: 0px;
    border-color: transparent;
    border-style: solid;
    border-radius: 0px;
    display: block;
    left: 462px;
    top: 26px;
}

.QSIInfoBar .QSI_EN_18 {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 180px;
    height: 21px;
    overflow: hidden;
    display: block;
}

.QSIInfoBar .QSI_EN_19 {
    margin: 0px 0px 2.1px;
    font-variant-numeric: normal;
    font-variant-east-asian: normal;
    font-stretch: normal;
    font-size: 14px;
    line-height: normal;
    font-family: Arial;
    color: #000;
    font-weight: 400;
}

.QSIInfoBar .QSI_EN_20 {
    font-size: 16px;
}

.QSIInfoBar .QSI_EN_21 {
    font-family: arial,helvetica,sans-serif;
}

.QSIInfoBar .QSI_EN_22 {
    position: absolute;
    z-index: 2000000003;
    width: 244px;
    height: 42px;
    background-color: transparent;
    border-width: 0px;
    border-color: rgb(0, 0, 0);
    border-style: solid;
    border-radius: 0px;
    display: block;
    left: 446px;
    top: 39px;
    cursor: pointer;
}

.QSIInfoBar .QSI_EN_23 {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 244px;
    height: 42px;
    overflow: hidden;
    display: block;
}

.QSIInfoBar .QSI_EN_24 {
    color: #007944;
    font-size: 16px;
    padding: 9px 16px;
    font-weight: 700;
    text-align: center;
}

.QSIInfoBar .QSI_EN_25 {
    vertical-align: middle;
    margin-right: 4px;
}

.QSIInfoBar .QSI_EN_28 {
    vertical-align: middle;
}

.QSIInfoBar .QSI_EN_29 {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    white-space: nowrap;
    border: 0;
}
/* FIN - CORRECTION POUR LA BANNIERE TNR À CAUSE DES CSP */
